<template>
  <div class="pharmacy-referral-container d-flex justify-start pa-md-4" :style="footerStyle">
    <div v-if="this.$vuetify.breakpoint.mdAndUp" class="pharmacy-referral-content">
      <v-card outlined elevation="3">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline d-flex justify-space-between" :class="minimized ? 'pa-0' : 'pb-3'">
              <div>
                <v-icon color="secondary">mdi-pharmacy</v-icon>
                <span class="ml-2 text-overline">Preferred pharmacy</span>
              </div>
              <div class="minimize d-flex justify-center align-center ml-2 mr-1">
                <v-icon color="secondary" @click="minimized = !minimized">{{
                  minimized ? 'mdi-plus' : 'mdi-window-minimize'
                }}</v-icon>
              </div>
            </div>

            <div v-if="!minimized && pharmacyId">
              <!-- Display pharmacy details if fetched in VueX -->
              <div v-if="pharmacy">
                <v-list-item-title class="mb-1"> {{ pharmacy.name }}</v-list-item-title>
                <v-list-item-subtitle class="ml-1">{{ pharmacy.address }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="pharmacy.phone1" class="ml-1 mb-1">
                  Phone: {{ pharmacy.phone1 }}
                </v-list-item-subtitle>
              </div>
              <!-- Show loading indicator if info is still being fetched -->
              <div class="d-flex justify-center mb-3" v-else>
                <div><v-progress-circular indeterminate></v-progress-circular></div>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </div>
    <div v-if="this.$vuetify.breakpoint.smAndDown">
      <v-expansion-panels class="pharmacy-referral-content" accordion flat>
        <v-expansion-panel class="d-flex flex-column">
          <v-expansion-panel-header class="d-flex">
            <div class="order-2 flex-grow-1 text-center">
              <span class="ml-2 text-overline">Preferred pharmacy</span>
            </div>
            <div class="order-3 spacer flex-grow-0">
              <v-icon color="secondary">mdi-pharmacy</v-icon>
            </div>
            <!-- <div class=" order-3 flex-grow-0"></div> -->
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card flat>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="mb-1"> {{ pharmacy.name }}</v-list-item-title>
                  <v-list-item-subtitle class="mb-1 card-subtitle">{{
                    pharmacy.address
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ pharmacy.phone1 }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<style lang="scss">
.card-subtitle {
  width: 100%;
}
.overline {
  width: 100%;
}
.spacer {
  width: 24px !important;
  height: 24px !important;
}
.minimize {
  cursor: pointer !important;
}
.pharmacy-referral-container {
  position: fixed;
  left: 0;
  z-index: 7;
}
@media all and (min-width: 960px) {
  .pharmacy-referral-container {
    width: 400px !important;
  }
}

@media all and (max-width: 768px) {
  .pharmacy-referral-content {
    width: 100% !important;
  }
  .pharmacy-referral-container {
    width: 100%;
    > div {
      width: 100% !important;
    }
  }
}
</style>
<script>
export default {
  name: 'PharmacyReferral',
  data() {
    return {
      minimized: false,
    };
  },
  props: {
    footerVisible: Boolean,
    pharmacyId: {
      required: false,
      type: String,
    },
    isPageflowV2: {
      required: false,
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    footerStyle() {
      if (!this.footerVisible) return 'bottom: 0px';
      if (this.$vuetify.breakpoint.smAndDown) return 'bottom: 84px';
      return 'bottom: 92px';
    },
    pharmacy() {
      const pharmacy = this.$store.state.registration?.patientData?.pharmacy;
      return !this.isPharmacyNull(pharmacy) && pharmacy;
    },
  },
  methods: {
    isPharmacyNull(pharmacy) {
      if (!pharmacy) {
        return true;
      }
      return Object.keys(pharmacy).every((key) => {
        switch (key) {
          case 'id':
            return true;
          default:
            return pharmacy[key] === null || pharmacy[key] === undefined;
        }
      });
    },
  },
  async mounted() {
    if (!this.isPageflowV2) {
      await this.$store.dispatch('setPreferredPharmacy', { pharmacyId: this.pharmacyId });
      if (!this.pharmacy) this.$emit('hide');
    }
  },
  watch: {
    pharmacyId(val) {
      if (!this.isPageflowV2) {
        this.$store.dispatch('setPreferredPharmacy', { pharmacyId: val });
      }
    },
  },
};
</script>
